<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-form @submit="confirm = true">
        <q-card-section class="bg-indigo-1 row justify-between items-center">
          <q-btn
            flat
            color="primary"
            label="back"
            @click="$router.go(-1)"
          ></q-btn>
          <a class="text-grey text-h6">IDENTITAS :</a>
        </q-card-section>
        <q-card-section class="bg-indigo-1 column q-col-gutter-md">
          <q-input
            filled
            @change="isChange = true"
            label="Nama"
            outlined
            v-model="musyrifDetail.nama"
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Nama Tidak Boleh Kosong'
            ]"
          ></q-input>

          <q-input
            filled
            readonly
            label="NIK"
            @change="isChange = true"
            outlined
            v-model="musyrifDetail.nik"
            class="col-6"
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Nama Tidak Boleh Kosong'
            ]"
          ></q-input>

          <q-input
            filled
            label="NIGN"
            @change="isChange = true"
            outlined
            v-model="musyrifDetail.nign"
            class="col-6"
          ></q-input>

          <q-input
            filled
            label="No.HP"
            @change="isChange = true"
            outlined
            v-model="musyrifDetail.hp"
            class="col-6"
          ></q-input>

          <q-input
            filled
            label="E-Mail"
            @change="isChange = true"
            outlined
            v-model="musyrifDetail.email"
            class="col-6"
          ></q-input>
        </q-card-section>
        <q-card-actions align="stretch">
          <q-btn
            outline
            class="full-width q-mb-sm"
            color="primary"
            label="Ubah Password"
            to="/pengaturaneditpassword"
          ></q-btn>
          <q-btn
            :disable="!isChange"
            class="full-width"
            type="submit"
            color="primary"
            label="simpan"
          ></q-btn>
        </q-card-actions>
      </q-form>
    </q-card>
    <q-dialog v-model="confirm">
      <q-card>
        <q-card-section>
          Apa anda yakin ingin menyimpan data?
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="close" color="primary" v-close-popup></q-btn>
          <q-btn
            unelevated
            label="Ya"
            color="primary"
            @click="simpan"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      confirm: false,

      isChange: false,
      musyrifDetail: {},
      selJenjang: ""
    };
  },
  async mounted() {
    await this.getdata();
  },
  methods: {
    async simpan() {
      await this.$http.put(`/pengaturan/user/update`, this.musyrifDetail);
      this.$q.notify({
        message: "Profil Berhasil Di Update",
        color: "posititve"
      });
      await this.getdata();
    },
    async getdata() {
      let resp = await this.$http.get(`/pengaturan/user/getdata/2`);
      // 9 -> id guru dummy
      this.musyrifDetail = resp.data;
    }
  }
};
</script>

<style lang="scss" scoped></style>
